angular.module("common")
    .factory("_window", function (_u) {
        var scrollCallbacks = [];
        var resizeCallbacks = [];

        (function triggerScrollCallbacks() {
            $(window).on("scroll", _u.throttle(function() {
                var changed = false;

                _.each(_.clone(scrollCallbacks), function(callback) {
                    changed = callback() || changed;
                });

                return changed;
            }));
        })();

        (function triggerResizeCallbacks() {
            $(window).on("resize", _u.throttle(function() {
                var changed = false;

                _.each(_.clone(resizeCallbacks), function(callback) {
                    changed = callback() || changed;
                });

                return changed;
            }));
        })();

        function on(event, callback, doNotWrap) {
            var wrapped = (doNotWrap) ? callback : _u.throttle(callback);

            $(window).on(event, wrapped);
        }

        function onAndOffOnDestroy(event, $scope, callback) {
            var wrapped = _u.throttle(callback);

            on(event, wrapped, true);
            offOnDestroy(event, $scope, wrapped);
        }

        function onScroll(callback) {
            scrollCallbacks.push(callback);
        }

        function offScroll(callback) {
            scrollCallbacks.remove(callback);
        }

        function onResize(callback, $scope) {
            resizeCallbacks.push(callback);

            if ($scope) {
                $scope.$on("$destroy", function () {
                    offResize(callback);
                });
            }
        }

        function offResize(callback) {
            resizeCallbacks.remove(callback);
        }


        function off(event, callback) {
            $(window).off(event, callback);
        }

        function offOnDestroy(event, $scope, callback) {
            $scope.$on("$destroy", function () {
                off(event, callback);
            });
        }

        function getWidth() {
            return $(window).width();
        }

        function getHeight() {
            return $(window).height();
        }

        function getPositionInCenter() {
            var $this = $(window);
            var width = $this.width();
            var height = $this.height();

            var centerX = width / 2;
            var centerY = height / 2;

            return {
                x: centerX,
                y: centerY
            }
        }

        return {
            on: on,
            onAndOffOnDestroy: onAndOffOnDestroy,
            onScroll: onScroll,
            onResize: onResize,
            off: off,
            offScroll: offScroll,
            offResize: offResize,
            getWidth: getWidth,
            getHeight: getHeight,
            getPositionInCenter: getPositionInCenter
        }
    });